import { HeaderComponent } from 'src/app/common/header/header.component'
import { BtnComponent } from 'src/app/ui-kit/component/btn/btn.component'

import { MainFaqComponent } from './components/main-faq/main-faq.component'
import { MainNewsComponent } from './components/main-news/main-news.component'
import { TrustServicesComponent } from './components/trust-services/trust-services.component'

import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'

import { SvgIcon } from 'src/app/shared/svg-icons/icons/svg-icons.enum'
import { SvgIconsModule } from 'src/app/shared/svg-icons/svg-icons.module'

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [HeaderComponent, BtnComponent, SvgIconsModule, MatIconModule, TrustServicesComponent, MainNewsComponent, MainFaqComponent],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent {
  getServices(): void {}

  svgIcons = SvgIcon
}
