<div class="mnc" [class.mnc--main]="isMainNews">
  <div *ngIf="card.img && isMainNews" class="mnc__img">
    <img [src]="card.img" [alt]="card.title" />
  </div>
  <div
    class="mt-16 md:text-background-white opacity-50 text-10 md:text-12 px-8 py-3 bg-background-white md:bg-transparent text-background-black md:px-0 md:py-0 flex"
  >
    {{ card.date }}
  </div>
  <a routerLink="'/'" class="mnc__title mt-16 text-13 md:text-16">
    {{ card.title }}
  </a>
</div>
