<div class="faq bg-background-main py-40 md:py-80">
  <div class="wrapper">
    <h2>Питання та відповіді</h2>
    <div class="mt-40 md:mt-80 flex items-start w-full justify-between gap-30">
      <div *ngFor="let col of cols" class="faq__col flex flex-col gap-30">
        <span class="faq__title opacity-50 text-16 relative">{{ col.title }}</span>

        <div class="faq__items accodrion">
          <mat-expansion-panel *ngFor="let item of col.items">
            <mat-expansion-panel-header>
              <mat-panel-title> {{ item.question }} </mat-panel-title>
            </mat-expansion-panel-header>
            <p [innerHTML]="item.answer"></p>
          </mat-expansion-panel>
        </div>
      </div>
    </div>
  </div>
</div>
