<div class="main">
  <div class="main__hero">
    <div class="main__header pr-16 md:pr-0">
      <app-header [isHero]="true" [isPortal]="true" />
    </div>

    <div class="main__hero-container flex flex-col justify-center items-center w-full h-full">
      <div class="main__hero-logs flex gap-6">
        <mat-icon [svgIcon]="svgIcons.HERALD" />
        <mat-icon [svgIcon]="svgIcons.PEN_LOGO" />
      </div>
      <div class="main__title mt-20">
        <h1 class="text-center">
          Кваліфікований <br />
          електронний підпис
        </h1>
      </div>
      <div class="main__hero-btn mt-50">
        <app-btn [title]="'Отримати послуги'" [hasGradient]="true" (clickEvent)="getServices()" />
      </div>
    </div>
  </div>

  <div class="main__services">
    <div class="wrapper">
      <app-trust-services />
    </div>
  </div>

  <div class="main__news">
    <app-main-news />
  </div>

  <div class="main__faq">
    <app-main-faq />
  </div>
</div>
