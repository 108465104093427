import { TrustServicesCard } from './shared/types'

import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { RouterLink } from '@angular/router'

@Component({
  selector: 'app-trust-services-card',
  standalone: true,
  imports: [RouterLink],
  templateUrl: './trust-services-card.component.html',
  styleUrl: './trust-services-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustServicesCardComponent {
  @Input({ required: true }) card!: TrustServicesCard
}
