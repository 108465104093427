import { FaqItem } from './shared/types'

export const commonFaq: FaqItem[] = [
  {
    question: 'Що таке ID картка?',
    answer: `Громадяни України, які є власниками ID-картки (паспорта громадянина України у формі картки), мають можливість отримати послугу з внесення особистого ключа кваліфікованого електронного підпису на безконтактний електронний носій (чип) ID-картки. Звертаємо вашу увагу, що ID-картки автоматично не містять у собі особистий ключ. За більш детальною інформацією щодо надання цієї послуги ви можете звернутись до територіальних підрозділів ДМС.
      <br />
      <br />
      У разі наявності відповідного пристрою для зчитування інформації з чипа ID-картки, ви можете автентифікувати себе наступним чином:
      <br />
      <br />
      <ol>
        <li>Оберіть зі списку відповідний тип пристрою для зчитування.</li>
        <li>Зчитайте особистий ключ з безконтактного електронного носія (чипа).</li>
        <li>Вкажіть у відповідному полі персональний ідентифікаційний номер (ПІН2), який ви самостійно вводили при отриманні ID-картки.</li>
      </ol>
`,
  },
  {
    question: 'Що таке MobileID?',
    answer: `MobileID – послуга електронної ідентифікації та кваліфікованого електронного підпису, яку надає оператор мобільного зв’язку. Ця послуга передбачає, що особистий ключ записується безпосередньо на спеціально призначену для цього SIM-карту.
      <br />
      <br />
      За більш детальною інформацією щодо можливості надання цієї послуги ви можете звернутись до вашого оператора мобільного зв’язку. Щоб підписати файл на сайті за допомогою MobileID, вам необхідно:
      <br />
      <br />
      <ol>
        <li>Обрати вашого мобільного оператора.</li>
        <li>Ввести ваш номер мобільного телефону.</li>
        <li>Підтвердити підпис за допомогою пін-коду, який ви створили ще при підключенні послуги.</li>
      </ol>`,
  },
  {
    question: 'Що таке хмарне сховище?',
    answer: `Якщо ваш особистий ключ зберігається за допомогою стороннього сервісу (зберігання у захищеному хмарному сховищі), то для накладання підпису на файл необхідно обрати розділ "Хмарний", обрати свого надавача зі списку, зчитати ключ і підписати файл.`,
  },
  {
    question: 'Що таке токен?',
    answer: `Токен - це спеціальний апаратно-програмний пристрій, який захищає особисті ключі від копіювання чи зміни зловмисниками. Токен може мати форму USB-пристрою або у формі смарт-картки (картки з чипом). Прикладами токенів є пристрої Алмаз-1К, Кристал-1 тощо.
      <br />
      <br />
      Для того, щоб скористатись токеном вам необхідно зчитати ключ обираючи розділ "Токен":
      <br />
      <br />
      <ol>
        <li>Підключити ваш токен через USB-порт (якщо у вас токен у формі USB-пристрою) або скористатися спеціальним зчитувачем інформації (якщо у вас токен у формі смарт-картки).</li>
        <li>Обрати зі списку свого надавача електронних довірчих послуг – суб’єкта, до якого ви зверталися для отримання електронного підпису.</li>
        <li>Зі списку токенів обрати тип пристрою, який ви хочете використати.</li>
        <li>Вказати пароль доступу до особистого ключа у відповідному полі.</li>
      </ol>`,
  },
  {
    question: 'Що таке файловий носій?',
    answer: `Файловий носій – це спеціальний файл, який містить ваш особистий ключ. Зазвичай цей файл має назву Key-6 з розширенням *.dat (зустрічаються також розширення *.pfx, *.pk8, *.zs2, *.jks).
      <br />
      <br />
      Для того, щоб скористатись файловим носієм вам необхідно зчитати ключ обираючи розділ "Файловий":
      <br />
      <br />
      <ol>
        <li>Обрати зі списку свого надавача електронних довірчих послуг – суб’єкта, до якого ви зверталися для отримання електронного підпису.</li>
        <li>Завантажити із зовнішнього носія чи власного комп’ютера файл з вашим особистим ключем.</li>
        <li>Вказати пароль доступу до особистого ключа у відповідному полі.</li>
      </ol>`,
  },
  {
    question: 'Що таке електронний підпис?',
    answer: `Електронний підпис (ЕП) дає змогу підтвердити цілісність електронного документа та ідентифікувати підписувача. Це аналог власноручного підпису. ЕП і електронний документ є надзвичайно зручними, ефективними і надійними засобами роботи із задокументованою інформацією в електронному вигляді. ЕП забезпечує достовірність і цілісність інформації, викладеної в документі, а сам електронний документ гарантує, що підписант має право на підписання документа і згоден з тим, що він підписує.
      <br />
      <br />
      Паперовий носій не забезпечує таких зручних інструментів. Безумовно, можна, приміром, перевірити звичайний підпис, провівши графологічну експертизу, однак для цього будуть потрібні час, зусилля і ресурси, тоді як при роботі з електронним підписом ці процедури здійснюються онлайн. Звісно, ЕП не забезпечує тієї гнучкості при обробленні інформації, яку дає папір, але при цьому дає зручні механізми роботи і контролю, практично виключає можливість підробки і проведення операцій "заднім числом".`,
  },
  {
    question: 'Що таке виїзна генерація?',
    answer: `Виїзна генерація - це послуга, яка передбачає виїзд спеціаліста Надавача на робоче місце клієнта для надання електронних довірчих послуг.
      <br />
      <br />
      Дана послуга доступна для наступних категорій користувачів: юридичні та самозайняті особи (адвокати, нотаріуси, приватні виконавці тощо).
      <br />
      <br />
      Послуга надається тільки в межах м. Київ та за попередньою оплатою.
      <br />
      <br />
      Вартість першої години виїзної генерації сплачується за тарифом 980 грн (до 10 послуг з генерації ключів (включно)). Кожні наступні півгодини послуги (за необхідності) сплачуються за тарифом 490,00 грн.
      <br />
      <br />
      Сума оплати за виїзну генерацію не включає вартість формування кваліфікованого сертифікату електронного підпису та/або печатки. Послуга з формування сертифікатів сплачується окремо.
      <br />
      <br />
      Форму для замовлення послуги можна заповнини натиснувши <a class="underline" href="/">тут</a>.
`,
  },
]

export const signFaq: FaqItem[] = []
