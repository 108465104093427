import { MainNewsCardComponent } from 'src/app/features/main/components/main-news-card/main-news-card.component'
import { News } from 'src/app/features/main/components/main-news-card/shared/types'

import { NgFor } from '@angular/common'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { MatIconModule } from '@angular/material/icon'
import { RouterLink } from '@angular/router'

import { SvgIcon } from 'src/app/shared/svg-icons/icons/svg-icons.enum'
import { SvgIconsModule } from 'src/app/shared/svg-icons/svg-icons.module'

@Component({
  selector: 'app-main-news',
  standalone: true,
  imports: [NgFor, SvgIconsModule, MatIconModule, RouterLink, MainNewsCardComponent],
  templateUrl: './main-news.component.html',
  styleUrl: './main-news.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNewsComponent {
  svgIcons = SvgIcon

  mainCard: News = {
    img: '/assets/images/main-news.png',
    date: '02.05.2024',
    title: 'Графік роботи пункту реєстрації кваліфікованого надавача електронних довірчих послу "Дія", за адрес',
  }

  cards: News[] = [
    {
      date: '02.05.2024',
      title: 'Графік роботи пункту реєстрації кваліфікованого надавача електронних довірчих послуг "Дія", послуг "Дія", за адресою ',
    },
    {
      date: '02.05.2024',
      title: '27.11.2023 пункт реєстрації кваліфікованого надавача електронних довірчих послуг "Дія", за адресою послуг "Дія", за адресою ',
    },
    {
      date: '02.05.2024',
      title: 'З 01.08.2023 в пунктах обслуговування кваліфікованого надавача електронних довірчих послуг "Дія", за адресою ',
    },
    {
      date: '02.05.2024',
      title: '10.03.2023 пункт реєстрації кваліфікованого надавача електронних довірчих послуг "Дія", за адресою',
    },
  ]
}
